<template>
  <div class="backenduser-list">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex lg12>
          <Toolbar :handle-add="addHandler" :enable-refresh="true" :handle-refresh="refreshData" :title="$t('backend_users')"/>

          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <BackendUserFilterForm
              :values="filters"
              ref="filterForm"
              slot="filter"
            />
          </DataFilter>

          <br/>

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="onUpdateOptions"
            class="elevation-1"
            item-key="@id"
            show-select
            v-model="selected"
          >
            <template slot="item.groups" slot-scope="{ item }">
              <ul>
                <li :key="_item.id" v-for="_item in item['groups']">
                  {{ _item.id }}
                </li>
              </ul>
            </template>
            <template slot="item.firstName" slot-scope="{ item }">
              {{ getProfileItemBasics(item.profile, 'firstName') }}
            </template>

            <template slot="item.lastName" slot-scope="{ item }">
              {{ getProfileItemBasics(item.profile, 'lastName') }}
            </template>
            <template slot="item.createdAt" slot-scope="{ item }">
              {{ formatDateTime(item['createdAt'], 'L') }}
            </template>
            <template slot="item.updatedAt" slot-scope="{ item }">
              {{ formatDateTime(item['updatedAt'], 'L') }}
            </template>
            <ActionCell
              :handle-delete="() => deleteHandler(props.item)"
              :handle-edit="() => editHandler(props.item)"
              :handle-show="() => showHandler(props.item)"
              slot="item.action"
              slot-scope="props"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import ListMixin from '../../mixins/ListMixin';
import ActionCell from '../../components/ActionCell';
import BackendUserFilterForm from '../../components/backenduser/Filter';
import DataFilter from '../../components/DataFilter';
import Toolbar from '../../components/Toolbar';
import ProfileMixin from "@/mixins/ProfileMixin";

export default {
  name: 'BackendUserList',
  servicePrefix: 'BackendUser',
  mixins: [ListMixin, ProfileMixin],
  components: {
    Toolbar,
    ActionCell,
    BackendUserFilterForm,
    DataFilter
  },
  data() {
    return {
      headers: [
        {text: this.$t('email'), value: 'email'},
        {text: this.$t('firstName'), value: 'firstName'},
        {text: this.$t('lastName'), value: 'lastName'},
        {text: this.$t('enabled'), value: 'enabled'},
        {
          text: this.$t('Actions'),
          value: 'action',
          sortable: false
        }
      ],
      selected: [],
      filters: {
        profile: {}
      }
    };
  },
  computed: {
    ...mapGetters('backendUser', {
      items: 'list'
    }),
    ...mapFields('backendUser', {
      deletedItem: 'deleted',
      error: 'error',
      isLoading: 'isLoading',
      resetList: 'resetList',
      totalItems: 'totalItems',
      view: 'view'
    })
  },
  methods: {
    ...mapActions('backendUser', {
      getPage: 'fetchAll',
      deleteItem: 'del',
      clearBackendUserData: 'resetAllData',
    }),
    resetFilter() {
      this.filters = {
        profile: {}
      };
    },
    refreshData(){
      this.clearBackendUserData();
      this.onSendFilter();
    }
  }
};
</script>
